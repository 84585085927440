<template>
 <div>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360">

    <rect style="fill:#fff" x="-8.48" y="-7.36" width="376.95" height="374.72" />
    <path style="fill:#2f348a;" d="M144.84,185.4a8,8,0,0,1,3.79,5.63c.57,2.5,1,5,1.5,7.55.27,1.26,0,1.64-1.4,1.66-4.69.08-4.67.13-5.38-4.48-.27-1.74-.59-3.48-1-5.19a4.1,4.1,0,0,0-2.94-3.19c-2.68-.78-3.29-.41-3.78,2.37s-1.12,5.49-1.48,8.27c-.24,1.8-1,2.29-2.78,2.22-3.76-.14-3.73,0-3.06-3.78,1.62-9.07,3.24-18.14,4.76-27.22.23-1.43.72-1.88,2.13-1.82,3,.13,6.11-.07,9.14.18,4.71.4,7.13,2.42,7.82,6.12a9.81,9.81,0,0,1-4.92,10.57C146.52,184.67,145.77,185,144.84,185.4Zm-8.07-3.12h3.09A6,6,0,0,0,146,176.4a5.91,5.91,0,0,0-.18-1.57c-.45-2.14-1.77-2.64-5.38-2.78-2.31-.1-2.07,1.44-2.32,2.75-.47,2.42-.87,4.86-1.33,7.48Z" />
    <path style="fill:#ee6724;" d="M65.25,227.39a33.26,33.26,0,0,1-17-9,16.8,16.8,0,0,1-5.13-14c.75.45.2,1.47.84,2,.47,4.32,2.83,7.56,5.9,10.41,1.64,1.51,3.42.19,5,0,2.54-.29,5.31-.13,7.52-1.87C74.74,219.82,87,219.79,99,213.85c2.66,1.22,5.54,1.72,8.34,2.51.59.17,1.33.07,1.21,1-6.45,5.23-14,7.76-22.08,8.83a1.5,1.5,0,0,0-.47-.08,54.58,54.58,0,0,1-18.94-.91C65.22,224.81,65.3,226.17,65.25,227.39Zm13.91-4.88c.45.94-.67,2.79.7,2.79s.59-1.83.57-2.81.5-2.65-.62-2.63S79.58,221.59,79.16,222.51Zm-19.38-.86a10.24,10.24,0,0,0,4.32-5A14.35,14.35,0,0,0,59.78,221.65Zm39.75,0c-1.34-1.77-2.36-3.88-4.48-4.91,1.22,1.93,2.36,3.88,4.48,4.94Z" />
    <path style="fill:#2f348a;" d="M183.3,166.73c2.48-.65,3.16.23,2.54,2.64-.57,2.16-.6,4.46-1.19,6.61-.51,1.85.47,1.59,1.51,1.38a13.81,13.81,0,0,1,6,.19c3.8.83,4.82,3.34,5.39,6.4,1.34,7.26-3.16,14.85-9.68,16.4a8.21,8.21,0,0,1-6.72-.78c-.55-.36-1.07-.83-1.66-.13-1.23,1.46-2.87.7-4.29.69s-.53-1.31-.44-1.84c1.11-6.79,2.33-13.56,3.52-20.33.48-2.76,1.05-5.51,1.36-8.29C179.87,167.29,180.68,165.9,183.3,166.73Zm7.9,19.91c0-1.84.11-3.79-1.88-4.84a4.1,4.1,0,0,0-4.53.44,4.83,4.83,0,0,0-1.92,3.23c-.39,2.29-.74,4.59-1.11,6.89-.51,3.16,1,4.59,4.09,3.85a6.1,6.1,0,0,0,4.14-3.72A13.7,13.7,0,0,0,191.2,186.64Z" />
    <path style="fill:#2f348a;" d="M246.49,186c-.09,7.33-3.86,12.91-9.77,14.32-2.29.55-4.62.67-6.72-.74a1.32,1.32,0,0,0-1.88,0l-.12.13c-1.16,1.09-2.76.48-4.15.41-.94,0-.5-1-.4-1.57q2.45-14.07,5-28.14v-.23c.64-3.43,2.2-4.35,5.69-3.56,1.21.27.79,1,.69,1.64-.42,2.61-.86,5.21-1.36,7.8-.24,1.2,0,1.61,1.29,1.27a13.57,13.57,0,0,1,2.37-.36C242,176.57,246.45,178.59,246.49,186Zm-16,6.48c-.11,3.09,1,4.14,3.53,3.83a5.75,5.75,0,0,0,4.28-3.14,14.69,14.69,0,0,0,1.81-6.89c.14-2.85-1.05-4.58-3.18-4.91a4.61,4.61,0,0,0-5.16,3.68c-.61,2.65-.93,5.36-1.27,7.44Z" />
    <path style="fill:#2f348a;" d="M316.3,177.91l-8.52,8.3c5.12,3,5.2,8.35,6.65,13a1.19,1.19,0,0,1,0,.66c-1.59.72-3.27.21-4.91.36-1,.09-1.16-.61-1.33-1.36-.38-1.72-.73-3.44-1.22-5.13a5.73,5.73,0,0,0-2.14-3.39c-2.2-1.51-3.44-1-3.83,1.63-.31,2.14-.71,4.27-1,6.41-.18,1.27-.61,1.9-2.12,1.89-4.3,0-4.33.05-3.59-4.26q2.34-13.49,4.75-27c.14-.82,0-2.11.92-2.25a16.76,16.76,0,0,1,5,.06c.69.1.19,1.09.09,1.68-.9,5.37-1.8,10.73-2.34,16.35l6-6.31c1.27-1.33,6.4-2.37,8-1.49.51.28,0,.61-.37.8-1.58-.9-3.18-.21-4.77-.28C313.12,179.17,314.74,177.41,316.3,177.91Z" />
    <path style="fill:#2f348a;" d="M211.86,176.89c7.83,0,11.18,3.79,10.37,9.91a28.11,28.11,0,0,1-1.59,6.52c-2.42,6.17-7.07,7.75-12.88,7.46-5.19-.26-8.2-4-8-9.24a19.26,19.26,0,0,1,2.1-8.31A11.29,11.29,0,0,1,211.86,176.89Zm-5.94,15.36a4,4,0,0,0,2.87,4.56c1.76.51,3.5-1,4.67-2.62a15.8,15.8,0,0,0,2.56-7.88c.22-1.92.16-4-2-4.92-2-.86-4.38.12-5.76,2.28A16.72,16.72,0,0,0,205.92,192.25Z" />
    <path style="fill:#2f348a;" d="M278.18,180.34a10.12,10.12,0,0,1,10-3.21c3,.59,4.51,2.19,4.16,5.21-.65,5.64-1.69,11.24-2.57,16.85-.2,1.33-1.24,1-2.07,1-4.27.08-4.27.08-3.55-4s1.36-7.88,2.09-11.83c.48-2.76-1.14-4.09-3.84-3.17s-4.83,4.39-5.19,7.49c-.4,3.56-1.1,7.08-1.67,10.63-.08.51-.21.92-.86.91-1.52,0-3.28.47-4.52-.13-1.69-.81-.18-2.49-.07-3.77.49-5.63,2.19-11.09,2.49-16.76.16-2.91,2.38-1.91,4-2.16C278.51,177.09,278.63,178.22,278.18,180.34Z" />
    <path style="fill:#2f348a;" d="M172.94,181.51c-.1,4.85-1.27,8.63-1.75,12.51-.16,1.26-.41,2.52-.6,3.79-.42,2.76-3,3.93-5.16,2.09a1.63,1.63,0,0,0-2.29-.25l-.08.07a8.68,8.68,0,0,1-6.3.7c-2.14-.47-3.93-1.58-4.36-3.94-.54-3,.14-5.67,2.59-7.54a9.28,9.28,0,0,1,8-2c1.19.28,2.72.06,3.24-.88a5.23,5.23,0,0,0,.54-4c-.28-.91-1.21-1-2-1a8.71,8.71,0,0,0-5.44,1.56c-.83.61-1.54,1-2-.31-.35-1-2.19-2-.22-3.15a15.4,15.4,0,0,1,13.37-1.49,3.21,3.21,0,0,1,2.46,3.83Zm-8.58,8.23c-1.59.18-3.22.18-4.61,1.13-1.61,1.08-2.36,3.4-1.49,4.83,1.11,1.82,2.83,1.55,4.48,1.06s3.5-5.29,2.85-6.63c-.29-.6-.78-.4-1.23-.39Z" />
    <path style="fill:#2f348a;" d="M261.14,176.9a22.16,22.16,0,0,1,2.43.22c3.68.68,5.07,2.61,4.47,6.31s-1.3,7.41-1.93,11.11c-.18,1-.2,2.08-.45,3.08s.36,2.25-1,2.67-3.12.82-4.28-.48c-.86-1-1.39-.6-2.19-.11a8.43,8.43,0,0,1-6.3.75c-4.7-1-6.27-6.51-2.91-10.45,2.41-2.82,5.66-3.67,9.29-3.15,1,.15,2.23.63,2.86-.42a5.15,5.15,0,0,0,.62-4.37c-.47-1.27-1.87-1-3-.9a7.56,7.56,0,0,0-4.06,1.31c-1,.73-1.73,1.3-2.52-.36-.62-1.31-1.41-2.26.45-3.24A17.87,17.87,0,0,1,261.14,176.9Zm-5.36,20.17c2.94,0,5.89-4.32,4.94-6.86a.69.69,0,0,0-.73-.5c-2,.18-4.11,0-5.68,1.54a3.84,3.84,0,0,0-1.17,4.15c.45,1.42,1.64,1.6,2.64,1.67Z" />
    <path style="fill:#2d2d7a;" d="M75.24,183c3.53.84,4.16.36,3.52-3a25.91,25.91,0,0,1-.44-5.9c.81-3.12.75-6.31,1.13-10.09-.79,1.06-1.13,1.69-1.87,1.82a4.45,4.45,0,0,1-1.2-2.76c0-.54.05-1.08.08-1.62a1.21,1.21,0,0,1,.45.16c.52.46.11,1.79,1.28,1.58s.49-1.17.59-1.8.06-1.56.76-1.6c3.39-.19,3.31-2.73,3.59-5H84a8.16,8.16,0,0,0,.71,3.48c1.18,2.35,1,4.66.54,7.18a16.14,16.14,0,0,0,1.2,9.22c1.72,3.09,3.41,6.21,5.21,9.26.27.46,1.08.6,1.63.88.86,1.15-.61,1.76-.63,2.71-.39.12-.45.44-.44.79a2,2,0,0,0-.94,1.6l-1.57,1.08c-.53-.08-1.26,0-.78-.92,1.37-2.66,1.14-3.94-1.11-6.47-2.11-1.93-3.17-4.57-4.53-7.28a10.22,10.22,0,0,0-.07,6.9v2.51l-.24.68a15.19,15.19,0,0,0-1.34,5.21c-1.21,1.69.27,3.33.11,5-.67.68-1.84,1-1.51,2.35l-.67.73-2.29.45-1-.13c-.39-.47-1-.32-1.48-.47-.37-.51-.08-.93.16-1.36a2.52,2.52,0,0,0,1.81-1.45l.28-.39c.91-2.23,2-4.42,1.77-6.94.13-1,.54-2.06,0-3.09v-.64c.59-3.17-.17-3.71-3.62-2.58Z" />
    <path style="fill:#f9b956;" d="M62.45,214.94c-2.21,1.74-5,1.58-7.52,1.87-1.6.18-3.38,1.5-5,0C46.86,214,44.5,210.72,44,206.4a17.42,17.42,0,0,1,2.49-9.73h0l.54-.85a18.53,18.53,0,0,1,5.25-5,9.94,9.94,0,0,1,1,7.43c-1.41,5.25-.42,9.74,4,13.3C58.89,212.89,60.82,213.67,62.45,214.94ZM47.58,212.1l.32.59,5.62-3-.32-.6C51.46,210.32,49,210.28,47.58,212.1Z" />
    <path style="fill:#e5b86e;" d="M86.49,174.62a16.14,16.14,0,0,1-1.2-9.22c.41-2.52.64-4.83-.54-7.18a8.16,8.16,0,0,1-.71-3.48c.69-2,.67-4.2,2.26-6,1.32,2.67,2.78,5.13,3.49,7.93,2.45,3.64,2.94,7.77,2.92,12.06,2.59-4.22,0-8.14-.41-12.17a1.55,1.55,0,0,0-.36-1.37c-.1-.52-.11-1.08-.63-1.42h0a3.82,3.82,0,0,0-1-1.93c-.52-1.92-1-3.86-1.58-5.76-.5-1.61-1.16-3-3.07-3.64-3.06-1-3.64-2.16-2.83-5.23a8.62,8.62,0,0,0,.39-3.07,2.21,2.21,0,0,0-2-2.06,2.3,2.3,0,0,0-2.75.94,5.5,5.5,0,0,0-.06,5.71c1.5,2.45,1.35,3.35-1.32,4.46-1.67.69-2.92,1.73-3.13,3.59-.64,5.62-4.32,9.86-6.62,14.73-.28.6-1,1-1.51,1.49l-.36-.33c3.09-4.86,6-9.8,7.22-15.52.46-2.15,1.32-3.9,3.63-4.65,1.74-.56,2.81-1.24,1.17-3.39-1.21-1.58-1-3.84-.34-5.8a3.3,3.3,0,0,1,4-2.31,3.58,3.58,0,0,1,3.31,3.52,9.63,9.63,0,0,1-.69,3.52c-.42,1.61-.1,2.92,1.73,3.34,3,.67,4.2,2.66,4.47,5.5a6.12,6.12,0,0,0,.6,2.56c2.68,4.21,3,9.08,3.91,13.77.37,1.88.57,3.83-.59,5.59-.32.48-.67,1-1.32.83a1.21,1.21,0,0,1-.94-1.24c0-.88-.43-1.86-.16-2.61,1-2.69-.3-4.85-1.47-7-1.4-2.59-1.8-5.63-3.73-8.27-1.14,2.82-1.94,5.37-.29,8.09a4.76,4.76,0,0,1,.87,3.67c-1.07,3.73-.71,7.43-.11,11.16A3.7,3.7,0,0,1,86.49,174.62Z" />
    <path style="fill:#ee6724;" d="M92.7,187.47c0-.95,1.49-1.56.63-2.71,7.86,1.93,14.72,5.47,19.6,12.19-1.59.72-3.64-.5-5.11,1.12,2,1.67,4.42,1.25,6.54,2l.11.63c.15.17.29.34.44.5v1.27c-2.67.38-5.16-.91-7.8-.81l-.33-.3c-.13-3.78-2-6.61-4.82-9l-1.56-1.23A23.44,23.44,0,0,0,92.7,187.47Z" />
    <path style="fill:#673b46;" d="M114.89,202.43v-1.27c2.26,5.73.63,10.82-2.78,15.45-3.12,4.25-7.48,7-12.32,8.91a46.88,46.88,0,0,1-17.58,3.39c2,0,2.51-2.37,4.3-2.68,8.11-1.07,15.63-3.6,22.08-8.83l3-3.28c.54-.77,1.39-1.37,1.34-2.45C115.29,208.94,115,205.66,114.89,202.43Z" />
    <path style="fill:#934132;" d="M86.53,226.23c-1.79.31-2.32,2.65-4.3,2.68a54.37,54.37,0,0,1-17-1.52c0-1.22,0-2.58,1.87-2.15a54.58,54.58,0,0,0,18.94.91A1.62,1.62,0,0,1,86.53,226.23Z" />
    <path style="fill:#f8a33a;" d="M53.29,198.27a9.94,9.94,0,0,0-1-7.43,28.34,28.34,0,0,1,8.73-4.6c-.23,1.21,1.12,1.9,1,3.06l-1.55,1.11C57,192.11,54.91,195,53.29,198.27Z" />
    <path style="fill:#e5b86e;" d="M76.46,161.49a11,11,0,0,1-.08,1.62c-1.19-.49-.87-1.59-.9-2.47-.07-1.82,0-3.63,0-6.07a16.69,16.69,0,0,1-4.62,5.76c-.1-.11-.21-.22-.32-.32,2-2.6,4.25-5,5.09-8.79C76.81,155,76.13,158.26,76.46,161.49Z" />
    <path style="fill:#f6982e;" d="M62,189.3c.1-1.16-1.25-1.85-1-3.06a18.18,18.18,0,0,1,5.69-1.66c.2.9.39,1.81.59,2.71Z" />
    <path style="fill:#f38924;" d="M67.25,187.3c-.2-.91-.39-1.82-.59-2.72h0c.56.09,1.12.16,1.52-.39,2.27-.81,4.69-.78,7-1.17h-.06c-1.74.31-1.81,2.38-3.19,3.06Z" />
    <path style="fill:#934132;" d="M114.36,200c-2.12-.71-4.56-.29-6.54-2,1.47-1.62,3.52-.4,5.11-1.12a7.27,7.27,0,0,1,1.53,2.67A1.18,1.18,0,0,1,114.36,200Z" />
    <path style="fill:#e5b86e;" d="M70.84,160.33a18.86,18.86,0,0,0-3.21,5.74c-.27.85-.5,1.84-1.64,1.61a2.08,2.08,0,0,1-1.63-2.09c-.08-1.14-.06-2.24,1.14-2.87l.36.33a7.64,7.64,0,0,0-.72,1.72c-.12.8,0,1.79.89,2,1.1.3.82-.89,1.11-1.44,1-1.86,1.56-4,3.38-5.34C70.63,160.1,70.74,160.21,70.84,160.33Z" />
    <path style="fill:#f6982e;" d="M46.5,196.66a17.43,17.43,0,0,0-2.5,9.73c-.64-.5-.09-1.52-.84-2A10.85,10.85,0,0,1,46.5,196.66Z" />
    <path style="fill:#e5b86e;" d="M77.58,165.87c.74-.13,1.08-.76,1.87-1.82-.38,3.78-.32,7-1.13,10.09A31.06,31.06,0,0,0,77.58,165.87Z" />
    <path style="fill:#f6982e;" d="M68.2,184.2a1.38,1.38,0,0,1-1.52.39A1.66,1.66,0,0,1,68.2,184.2Z" />
    <path style="fill:#673b46;" d="M114.36,200a1.17,1.17,0,0,0,.1-.41.72.72,0,0,1,0,1C114.43,200.45,114.4,200.24,114.36,200Z" />
    <path style="fill:#f6982e;" d="M47,195.85l-.54.85A.82.82,0,0,1,47,195.85Z" />
    <path style="fill:#f4e3d5;" d="M53.29,198.27c1.62-3.31,3.75-6.16,7.15-7.86l.88.15-2.14,1.68a7.44,7.44,0,0,0-2.6,2.51h0l-.42.49c-3.71,5.21-3.21,11,1.29,15l.58.48h0l1,.73.36.31a.75.75,0,0,0,.83.41l.74.5h0a.93.93,0,0,0,1,.52h0a5.82,5.82,0,0,0,3.12,1.5c11.25,4.35,22.28,3.94,33.07-1.56.61-.1,1.2-.16,1,.78-12,5.94-24.25,6-36.61,1.08-1.63-1.27-3.56-2.05-5.19-3.37C52.87,208,51.88,203.52,53.29,198.27Z" />
    <path style="fill:#934132;" d="M99.06,213.86c.21-.94-.38-.88-1-.78a3.7,3.7,0,0,1,2.69-1.64c-.25,1.36.72.45,1.12.6,2.89,2.39,6.37,2,9.74,2.08l-3,3.28c.12-1-.62-.86-1.21-1C104.6,215.58,101.72,215.08,99.06,213.86Z" />
    <path style="fill:#f4e3d5;" d="M79.16,222.51c.42-.92-.5-2.62.65-2.65s.61,1.7.62,2.63.8,2.81-.57,2.81S79.61,223.45,79.16,222.51Z" />
    <path style="fill:#f4e3d5;" d="M59.78,221.65a14.35,14.35,0,0,1,4.32-5A10.24,10.24,0,0,1,59.78,221.65Z" />
    <path style="fill:#f4e3d5;" d="M99.53,221.68c-2.12-1.06-3.26-3-4.48-4.91C97.17,217.8,98.19,219.91,99.53,221.68Z" />
    <path style="fill:#2d2d7a;" d="M316.3,177.91c-1.56-.5-3.18,1.26-4.72-.32,1.59.07,3.19-.62,4.77.28Z" />
    <path style="fill:#2f348a;" d="M70.52,160c-1.82,1.34-2.38,3.48-3.38,5.34-.29.55,0,1.74-1.11,1.44-.84-.23-1-1.22-.89-2a7.64,7.64,0,0,1,.72-1.72c.51-.5,1.23-.89,1.51-1.49,2.3-4.87,6-9.11,6.62-14.73.21-1.86,1.46-2.9,3.13-3.59,2.67-1.11,2.82-2,1.32-4.46a5.5,5.5,0,0,1,.06-5.71,2.3,2.3,0,0,1,2.75-.94,2.21,2.21,0,0,1,2,2.06,8.62,8.62,0,0,1-.39,3.07c-.81,3.07-.23,4.27,2.83,5.23,1.91.6,2.57,2,3.07,3.64.59,1.9,1.06,3.84,1.58,5.76.1.76-.34,1.78,1,1.93h0c0,.56-.1,1.18.63,1.42a1.55,1.55,0,0,1,.36,1.37c-.73,2.45-1.68-.05-2.51.11-.71-2.8-2.17-5.26-3.49-7.93-1.59,1.75-1.57,4-2.26,6h-.91c-.13-.17.21-.67-.36-.52l.36.51c-.28,2.32-.2,4.86-3.59,5.05-.7,0-.66.95-.76,1.6s.46,1.62-.59,1.8-.76-1.12-1.28-1.58a1.21,1.21,0,0,0-.45-.16c-.33-3.23.35-6.53-.85-10.27C74.77,155,72.5,157.41,70.52,160Z" />
    <path style="fill:#ee6724;" d="M81.66,191.57A15.19,15.19,0,0,1,83,186.36l4.86.63v-.64l-4.66-.67v-2.51l4.6.38c2.25,2.53,2.48,3.81,1.11,6.47-.48.95.25.84.78.92.53.48,1.59.33,1.62,1.39-3,.57-5.34,2.37-7.85,3.86,3.89,1.23,5.59-1.86,7.84-3.88l1.44-.48a9,9,0,0,1,4.88,6.75c-.05,1,.88.72,1.39,1,.26.81.59,1.73-.87,1.24s-2.69-1.09-4-1.65a16.24,16.24,0,0,0-5-1.1c-2.37-.26-4.93.1-7-1.54C82.55,194.81,82.73,193.13,81.66,191.57Z" />
    <path style="fill:#ea7f31;" d="M78.85,186.27c.54,1,.13,2.06,0,3.09-.39,1-.83,2.06-1.15,3.12-.39,1.33-1,1.74-2.4,1.14-1.67-.72-3.46-1.16-5.19-1.73l-.28-.32c1.11-.17,1.37-.9,1.21-1.88-.13-.76-.27-1.52-.41-2.28C73.3,186.51,76.1,186.58,78.85,186.27Z" />
    <path style="fill:#ee6724;" d="M79.59,199.65c.23-.24.45-.49.67-.73a3.27,3.27,0,0,0,2.68.67,5.43,5.43,0,0,1,3.82,1c.5.44.72,1.36,1.69,1h0c.57,2.1,3.07,2.48,3.88,4.36-2.83-1.29-5.15-3.38-7.77-5-1.41-.87-2.09-1.09-2.44.93-.5,3-1.29,5.89-2,8.84C79.3,207,80,203.3,79.59,199.65Z" />
    <path style="fill:#f5f4f6;" d="M79.59,199.65c.36,3.65-.29,7.35.55,11l.56.5-.62,3.69h-.36q-.9-3.76-1.78-7.53c-.22-1.39-.43-2.78-.65-4.17,0-.62-.66-.64-.89-1a1.51,1.51,0,0,0,.93-1.92l0-.08Z" />
    <path style="fill:#876874;" d="M86.76,200.55a5.43,5.43,0,0,0-3.82-1,3.27,3.27,0,0,1-2.68-.67c-.33-1.32.84-1.67,1.51-2.35h.38c2.08,1.64,4.64,1.28,7,1.54a16.24,16.24,0,0,1,5,1.1c-1.88.84-3.89.23-5.83.56-.58.1-.91.15-.73.81Z" />
    <path style="fill:#f4e3d5;" d="M74.83,199.5c.5.15,1.09,0,1.48.47l-7.19,4.8c2,.5,3.17-1.4,4.85-1.34-1.71,1.82-4.08,2.46-6.25,3.47h-.89c-.39-.06-.82-.06-1-.51l.49-.72a56.17,56.17,0,0,0,6.24-5.6.37.37,0,0,0,0-.29Z" />
    <path style="fill:#f4e3d5;" d="M76.8,196.69a2.55,2.55,0,0,1-1.8,1.45,44.75,44.75,0,0,0-11.6.94,1.32,1.32,0,0,0-.06.39h-.88l-.46-1.2,1.81-.53c2.7-.35,5.39-.72,8.08-1,.73-.08.67-.5.64-1C73.93,196.09,75.2,197.13,76.8,196.69Z" />
    <path style="fill:#f4e3d5;" d="M70.11,191.89c1.73.57,3.52,1,5.19,1.73,1.41.6,2,.19,2.4-1.14.32-1.06.76-2.08,1.15-3.12.19,2.52-.86,4.71-1.77,6.94-2.65-1.1-4.79-3.4-7.92-3.41l-.94-.21Z" />
    <path style="fill:#efd1ac;" d="M78.85,186.27c-2.75.31-5.55.24-8.22,1.14a21.9,21.9,0,0,0-7.34,2.43l-2,.72-.88-.15L62,189.3l5.26-2L72,186.11l6.81-.48Z" />
    <path style="fill:#f4e3d5;" d="M87.86,183.55l-4.6-.38a10.22,10.22,0,0,1,.07-6.9C84.69,179,85.75,181.62,87.86,183.55Z" />
    <path style="fill:#ee6724;" d="M78.8,185.63l-6.81.48c1.38-.68,1.45-2.75,3.19-3.06C78.63,181.92,79.39,182.46,78.8,185.63Z" />
    <path style="fill:#ea7f31;" d="M74,203.43c-1.68-.06-2.8,1.84-4.85,1.34l7.19-4.8,1,.13a1.51,1.51,0,0,1-.82,2l-.08,0A4.06,4.06,0,0,0,74,203.47Z" />
    <path style="fill:#ea7f31;" d="M69.16,192.89c3.13,0,5.27,2.31,7.92,3.41l-.28.39c-1.6.44-2.87-.6-4.28-1h0c0-.72-.58-.46-1-.47C71.2,194.07,69.7,194,69.16,192.89Z" />
    <path style="fill:#ea7f31;" d="M63.39,199.08a44.75,44.75,0,0,1,11.6-.94c-.24.43-.53.85-.16,1.36l-2.3.26c-.54-.86-1.44-.57-2.18-.59C68,199.09,65.71,199.1,63.39,199.08Z" />
    <path style="fill:#efd1ac;" d="M92.7,187.47a23.37,23.37,0,0,1,7.68,3.63l-.52.46c-1.28-.44-2.21-1.67-3.7-1.63h0c-1.06-1.14-2.52-1.34-3.92-1.68C92.25,187.91,92.31,187.59,92.7,187.47Z" />
    <path style="fill:#673b46;" d="M92.8,191.82l-1.44.49h0c0-1.06-1.09-.91-1.62-1.39l1.57-1.07h.14c.54,1.47,2.16,0,2.85,1C94.05,191.57,93.13,191.24,92.8,191.82Z" />
    <path style="fill:#ee6724;" d="M92.26,188.26c1.4.34,2.86.54,3.91,1.68l-4.71-.1h-.14A2,2,0,0,1,92.26,188.26Z" />
    <path style="fill:#f4e3d5;" d="M82.15,196.52h-.38c.16-1.67-1.32-3.31-.11-5C82.73,193.13,82.55,194.81,82.15,196.52Z" />
    <path style="fill:#efd1ac;" d="M83.24,185.68l4.66.67V187l-4.9-.63Z" />
    <path style="fill:#f4e3d5;" d="M47.58,212.1c1.45-1.82,3.88-1.78,5.62-3l.32.6-5.62,3Z" />
    <path style="fill:#2d2d7a;" d="M89.79,156.72c.83-.16,1.78,2.34,2.51-.11.39,4,3,7.95.41,12.17C92.73,164.49,92.24,160.36,89.79,156.72Z" />
    <path style="fill:#2d2d7a;" d="M91.31,153.82c-1.3-.15-.86-1.17-1-1.93A3.82,3.82,0,0,1,91.31,153.82Z" />
    <path style="fill:#2d2d7a;" d="M91.94,155.24c-.73-.24-.61-.86-.63-1.42C91.83,154.16,91.84,154.72,91.94,155.24Z" />
    <path style="fill:#934132;" d="M114.89,202.43c.09,3.23.4,6.51-1.94,9.24l-1.4.07c-1.69-.48-3-1.86-4.82-2.05h0c-.11-.58-.6-.51-1-.58,0-.33,0-.66-.44-.79a.79.79,0,0,1,0-.43c1.71-1.78,1.88-4,1.82-6.29C109.73,201.52,112.22,202.81,114.89,202.43Z" />
    <path style="fill:#f4e3d5;" d="M101.94,192.33c2.77,2.38,4.69,5.21,4.82,9l-.91-.3a1.2,1.2,0,0,0-.15-.37c-.3-3.21-2.07-5.64-4.25-7.84Z" />
    <path style="fill:#876874;" d="M105.85,201l.91.3.33.29c.06,2.29-.11,4.51-1.82,6.29l-.89-.15A14,14,0,0,0,105.85,201Z" />
    <path style="fill:#efd1ac;" d="M101.94,192.33l-.49.49-1.59-1.26.52-.46Z" />
    <path style="fill:#ee6724;" d="M111.55,211.74l1.4-.07c0,1.08-.8,1.68-1.34,2.45-3.36-.12-6.84.31-9.73-2.08,1.15-1.23,2.29-2.47,3.43-3.7.39.13.43.46.44.79,0,.79.4.87,1,.58h0c1.14,1.54,3,2,4.56,2.75C111.63,212.6,111.54,212,111.55,211.74Z" />
    <path style="fill:#ee6724;" d="M77.94,207.27c.59,2.51,1.19,5,1.78,7.53h.36c.21-1.23.42-2.46.62-3.69,1-2.29,1.18-4.77,1.74-7.16.32-1.38.73-1.64,2-.95,2.58,1.4,5.24,2.67,7.86,4,.42.21.84.53,1.24.11.08-.08-.23-.53-.36-.8-.54-2.3-2.88-3-4.13-4.67,1.85,0,13.68,5.06,14.92,6.41a10,10,0,0,1-3.22,3.36,3.7,3.7,0,0,0-2.69,1.64C87.28,218.58,76.25,219,65,214.64c1.5-.95,3.5-1,4.69-2.49,1.25.12,2-.87,3-1.37C74.54,209.8,75.85,208,77.94,207.27Z" />
    <path style="fill:#f9b956;" d="M59.38,211.74l-.37-.31c-.07-.57-.45-.74-1-.73h0l-.58-.48c-4.5-4-5-9.79-1.29-15l.42-.49h0c.66.12,1.47-.12,1.21,1.17a7.26,7.26,0,0,1-1.63,3.61l1.56-.34.84.42c-.08,2.34.73,4.51,1.19,6.75C60.12,208.18,60.89,210,59.38,211.74Z" />
    <path style="fill:#f8a33a;" d="M59.38,211.74c1.51-1.7.74-3.56.37-5.38-.46-2.24-1.27-4.41-1.19-6.75h2.89c-.12,3.15,2.16,5.9,1.49,9.14h0l-.51.48-.5.46-.11.21.12-.2h.57a.91.91,0,0,1,.4.06c0,1.22,1.81,3-1,3.4h0c-.17-.49-.55-.54-1-.52h0l-.74-.5A.74.74,0,0,1,59.38,211.74Z" />
    <path style="fill:#f6982e;" d="M61.32,190.56l2-.72c.51.83,1.38.52,2.1.65v.76c0,1-.28,2.16.09,3,.81,1.93.09,2.87-1.64,3.47l-1.81.53H61c0-1.26-.08-2.51,0-3.77a1.85,1.85,0,0,0-1.8-2.25Z" />
    <path style="fill:#f6982e;" d="M61.88,213.14c2.83-.39,1-2.18,1-3.4v-.52h0c1.61-.31,3.18-.69,4-2.37h.89c1,1.64.51,3.8,2,5.25-1.19,1.53-3.19,1.54-4.69,2.49A5.81,5.81,0,0,1,61.88,213.14Z" />
    <path style="fill:#f8a33a;" d="M59.18,192.24c1.37.13,1.85.93,1.8,2.25s0,2.51,0,3.77c-.61,2-2.48-.43-3.28.93l-1.56.34a7.26,7.26,0,0,0,1.63-3.61c.26-1.29-.55-1-1.21-1.17A7.58,7.58,0,0,1,59.18,192.24Z" />
    <path style="fill:#f8a33a;" d="M58.05,210.7c.51,0,.89.16,1,.73Z" />
    <path style="fill:#f6982e;" d="M60.92,212.64c.43,0,.81,0,1,.52A.93.93,0,0,1,60.92,212.64Z" />
    <path style="fill:#f8a33a;" d="M57.48,210.22l.58.48Z" />
    <path style="fill:#f6982e;" d="M60.21,212.15l.74.5Z" />
    <path style="fill:#f8a33a;" d="M56.61,194.73l-.42.49Z" />
    <path style="fill:#f4e3d5;" d="M100.76,211.44a10,10,0,0,0,3.22-3.36l.4-.32.89.15a.79.79,0,0,0,0,.43c-1.14,1.23-2.28,2.47-3.43,3.7C101.48,211.89,100.51,212.8,100.76,211.44Z" />
    <path style="fill:#2d2d7a;" d="M83.13,154.78l-.36-.51c.57-.15.23.35.36.52Z" />
    <path style="fill:#934132;" d="M104.38,207.76l-.4.32c-1.24-1.35-13.07-6.44-14.92-6.41l-.63-.17h0l-.84-1c-.18-.66.15-.71.73-.81,1.94-.33,4,.28,5.83-.56,1.34.56,2.66,1.18,4,1.65s1.13-.43.87-1.24h3c-1.38-1-2.71-1.88-4.31-1a9,9,0,0,0-4.88-6.75c.33-.59,1.25-.26,1.51-1-.69-1-2.31.52-2.85-.95l4.71.1h0a3.45,3.45,0,0,0,1.9,3.34c2.47,1.5,4.12,3.73,5.83,5.93a3.06,3.06,0,0,0,1.81,1.46,1.2,1.2,0,0,1,.15.37A14,14,0,0,1,104.38,207.76Z" />
    <path style="fill:#f4e3d5;" d="M91.36,192.31c-2.25,2-4,5.11-7.84,3.88,2.51-1.49,4.87-3.29,7.85-3.86Z" />
    <path style="fill:#673b46;" d="M97.68,198.58c1.6-.89,2.93,0,4.31,1H99.07C98.56,199.3,97.63,199.62,97.68,198.58Z" />
    <path style="fill:#f38924;" d="M65.39,190.49c-.72-.13-1.59.18-2.1-.65a21.9,21.9,0,0,1,7.34-2.43c.14.76.28,1.52.41,2.28.16,1-.1,1.71-1.21,1.88l-.16-.1a1.12,1.12,0,0,0-1.26-.47C67.33,191.27,66.44,190.4,65.39,190.49Z" />
    <path style="fill:#efd1ac;" d="M65.39,190.49c1-.09,1.94.78,3,.51.32.44.8.43,1.26.47l.16.1.28.32-1.89.79-2.87-1.43Z" />
    <path style="fill:#f4e3d5;" d="M93.19,206.34c.13.27.44.72.36.8-.4.42-.82.1-1.24-.11-2.62-1.34-5.28-2.61-7.86-4-1.28-.69-1.69-.43-2,.95-.56,2.39-.77,4.87-1.74,7.16l-.56-.5c.67-2.95,1.46-5.87,2-8.84.35-2,1-1.8,2.44-.93,2.62,1.62,4.94,3.71,7.77,5Z" />
    <path style="fill:#efd1ac;" d="M93.19,206.34l-.88-.48C91.5,204,89,203.6,88.43,201.5l.63.17C90.31,203.34,92.65,204,93.19,206.34Z" />
    <path style="fill:#efd1ac;" d="M87.61,200.53l.84,1c-1,.39-1.19-.53-1.69-1Z" />
    <path style="fill:#f38924;" d="M69.69,212.15c-1.46-1.45-1-3.61-2-5.25,2.17-1,4.54-1.65,6.25-3.47h0c1.19.58,2.1-1.36,3.33-.37.22,1.39.43,2.78.65,4.17-2.09.68-3.4,2.53-5.29,3.51C71.68,211.28,70.94,212.27,69.69,212.15Z" />
    <path style="fill:#f4e3d5;" d="M77.29,203.1c-1.23-1-2.14,1-3.33.37a4.08,4.08,0,0,1,2.44-1.42C76.63,202.46,77.31,202.48,77.29,203.1Z" />
    <path style="fill:#f6982e;" d="M62.94,208.79c.67-3.24-1.61-6-1.49-9.14l1-.2h.88l4.88.25c-.25.34-.64.47-.71,1.07-.18,1.66-.18,3.4-1.21,4.86l-.49.72Z" />
    <path style="fill:#f38924;" d="M66.29,205.65c1-1.46,1-3.2,1.21-4.86.07-.6.46-.73.71-1.07h1c.93.65,2,.29,3,.28a1.27,1.27,0,0,1,.39,0A56.32,56.32,0,0,1,66.29,205.65Z" />
    <path style="fill:#efd1ac;" d="M62.94,208.79l2.86-2.42c.21.45.64.45,1,.51-.78,1.68-2.35,2.06-4,2.37l.06-.48Z" />
    <path style="fill:#efd1ac;" d="M69.18,199.73h-1l-4.88-.25a1.32,1.32,0,0,1,.06-.39c2.32,0,4.64,0,7,.09.74,0,1.64-.27,2.18.59a.37.37,0,0,1,0,.29,1.28,1.28,0,0,0-.39,0C71.21,199.38,70.18,199.69,69.18,199.73Z" />
    <path style="fill:#f38924;" d="M65.35,191.25l2.87,1.43.94.21c.54,1.07,2,1.18,2.42,2.4.12.58.58.45,1,.47h0c0,.49.09.91-.64,1-2.69.32-5.38.69-8.08,1,1.73-.6,2.45-1.54,1.64-3.47C65.07,193.41,65.36,192.27,65.35,191.25Z" />
    <path style="fill:#efd1ac;" d="M62.45,199.45l-1,.2H58.56l-.84-.42c.8-1.36,2.67,1.11,3.28-.93h1C62.15,198.67,62.3,199.06,62.45,199.45Z" />
    <path style="fill:#f4e3d5;" d="M72.55,195.76c-.39,0-.85.11-1-.47C72,195.3,72.5,195,72.55,195.76Z" />
    <path style="fill:#ee6724;" d="M105.7,200.66a3.06,3.06,0,0,1-1.81-1.46c-1.71-2.2-3.36-4.43-5.83-5.93a3.45,3.45,0,0,1-1.9-3.34c1.49,0,2.42,1.19,3.7,1.63l1.59,1.26C103.63,195,105.4,197.45,105.7,200.66Z" />
    <path style="fill:#f4e3d5;" d="M111.55,211.74c0,.27.08.86-.26.7-1.6-.76-3.42-1.21-4.56-2.75C108.54,209.88,109.86,211.26,111.55,211.74Z" />
    <path style="fill:#f4e3d5;" d="M106.76,209.71c-.61.29-1,.21-1-.58C106.16,209.2,106.65,209.13,106.76,209.71Z" />
    <path style="fill:#f6982e;" d="M62.93,208.77l-.06.48h0a1.82,1.82,0,0,1-.4.11l-.08-.08Z" />
    <path style="fill:#f6982e;" d="M62.5,209.68h-.57l.5-.46.08.08Z" />
    <path style="fill:#efd1ac;" d="M62.5,209.68v-.35a1.82,1.82,0,0,0,.4-.11v.52A.91.91,0,0,0,62.5,209.68Z" />
    <path style="fill:#f6982e;" d="M61.93,209.72l-.12.2.11-.21Z" />
    <path style="fill:#ea7f31;" d="M69.67,191.47a1.32,1.32,0,0,1-1.26-.47A1.12,1.12,0,0,1,69.67,191.47Z" />
    <path style="fill:#f6982e;" d="M69.18,199.73c1,0,2-.35,3,.28C71.14,200,70.11,200.38,69.18,199.73Z" />
</svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>